
import styles from '../styles/blogs.module.css';

import img01 from '../images/history_01.jpg';
import elephant from '../images/elephant.png';
import blog_20220325 from '../images/20220325.jpg';
import blog_20220330 from '../images/20220330.png';
import blog_20220506 from '../images/20220506.jpg';
import blog_20221030 from '../images/20221030.jpg';
import blog_20221103 from '../images/20221103.jpg';
import blog_20221119_01 from '../images/20221119-01.jpg';
import blog_20221119_02 from '../images/20221119-02.jpg';
import blog_20221119_03 from '../images/20221119-03.jpg';
import blog_20230311 from '../images/20230311.jpg';
import blog_20231211_01 from '../images/20231211-01.jpg';
import blog_20231211_02 from '../images/20231211-02.jpg';
import blog_20240725_01 from '../images/20240725-natibu.png';
import blog_20241224_koala from '../images/20241224-koala.png';


const Blogs = () => {

    const linkHerbLand = "https://herbland.co.za/";
    const linkSheldrick = "https://www.sheldrickwildlifetrust.org/";
    const linkNatibu = "https://www.sheldrickwildlifetrust.org/orphans/natibu";

    const target = "_blank";

    return (
        <>
            <div className={styles.blogPage}>
                <h1>Blog Articles</h1>

                <article>
                    <div className={styles.blogImgMultiLeft}>
                        <div className={styles.blogImgMulti}>
                            <div className={styles.blogImgMultiImg}>
                                <img src={blog_20241224_koala}
                                     className={styles.blogImgSm}
                                     alt="cute koala, maybe not found in WA, but still an Australian icon"
                                     title="cute koala, maybe not found in WA, but still an Australian icon"/>
                                <div className={styles.blogImgDropdown}>
                                    <img src={blog_20241224_koala}
                                         alt="cute koala, maybe not found in WA, but still an Australian icon"
                                         title="cute koala, maybe not found in WA, but still an Australian icon"/>
                                    <p className={styles.blogCaption}>A cute koala.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <h2>An Unexpected Adventure</h2>

                        <pre>2024-12-24</pre>

                        <p>Quite unplanned and sudden, I now find myself in ...</p>

                        <p>The Land Down Under!</p>

                        <p>This was not supposed to happen, but things just worked out this way, and now I find myself
                            here,
                            in the land of koalas and kangaroos.</p>

                        <p>I have been here just over two weeks now, and have enjoyed every day, in spite of the fact
                            that I stepped
                            off the plane into a heat wave. Temperatures have been up in the forties on some days, but
                            still great
                            to be here. We are once again quite close to the coast, and can walk down to the beach,
                            which is only
                            800 metres away.</p>

                        <p>I am still not sure whether my immigration application will be successful, but all seems to
                            be going well so far.</p>

                        <p>So here's wishing the world a very Merry Christmas, and may 2025 be a wonderful year.</p>

                    </div>
                </article>
                <hr/>

                <article>
                    <div className={styles.blogImgMultiRight}>
                        <div className={styles.blogImgMulti}>
                            <div className={styles.blogImgMultiImg}>
                                <img src={blog_20240725_01}
                                     className={styles.blogImgSm}
                                     alt="my beautiful baby elephant, Natibu, at the Sheldrick Wildlife Trust in Kenya"
                                     title="my beautiful baby elephant, Natibu, at the Sheldrick Wildlife Trust in Kenya"/>
                                <div className={styles.blogImgDropdown}>
                                    <img src={blog_20240725_01}
                                         alt="my beautiful baby elephant, Natibu, at the Sheldrick Wildlife Trust in Kenya"
                                         title="my beautiful baby elephant, Natibu, at the Sheldrick Wildlife Trust in Kenya"/>
                                    <p className={styles.blogCaption}>Natibu, my adopted baby elephant.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <h2>Best Birthday Present EVER</h2>

                        <pre>2024-07-25</pre>

                        <p>Yesterday was my birthday, and my son gave me the best birthday present I have ever had.</p>

                        <p>He gifted me the adoption of an orphaned baby elephant, <a href={linkNatibu}
                                                                                      target={target}>Natibu</a>,
                            at the <a href={linkSheldrick}
                                      target={target}>Sheldrick Wildlife Trust</a>'s
                            Kaluku Neonate Nursery in Kenya! </p>

                        <p>Elephants have always been my favourite creature on the planet. They are so very much worth
                            saving.  The world would be a happier place if we all lived by the family values and behaviours
                            of elephants.</p>

                    </div>
                </article>
                <hr/>

                <article>

                    <div className={styles.blogImgMultiLeft}>

                        <div className={styles.blogImgMulti}>

                            <div className={styles.blogImgMultiImg}>
                                <img src={blog_20231211_01}
                                     className={styles.blogImgSm}
                                     alt="a bushbuck ram, strolling across the hill"
                                     title='a bushbuck ram, strolling across the hill'/>

                                <div className={styles.blogImgDropdown}>
                                    <img src={blog_20231211_01}
                                         alt="a bushbuck ram, strolling across the hill"
                                         title='a bushbuck ram, strolling across the hill'/>
                                    <p className={styles.blogCaption}>A bushbuck ram, strolling across the hill at the
                                        back of our garden.</p>
                                </div>
                            </div>

                            <div className={styles.blogImgMultiImg}>
                                <img src={blog_20231211_02}
                                     className={styles.blogImgSm}
                                     alt="he found a delicious bush to browse on, not really concerned about us watching"
                                     title='he found a delicious bush to browse on, not really concerned about us watching'/>

                                <div className={styles.blogImgDropdown}>
                                    <img src={blog_20231211_02}
                                         alt="he found a delicious bush to browse on, not really concerned about us watching"
                                         title='he found a delicious bush to browse on, not really concerned about us watching'/>
                                    <p className={styles.blogCaption}>He knows that we are watching, but is not too
                                        concerned, continued browsing on the bush after looking at us for a while.</p>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div>
                        <h2>A New Visitor</h2>

                        <pre>2023-12-11</pre>

                        <p>Early morning ...</p>

                        <p>As I looked out of the window, there was this bushbuck ram, inside our garden, browsing
                            contentedly on the bushes against the fence. Then he climbed further up the hill, and made
                            his way over to a bush right in the middle of the steep part of the hill. He looked up and
                            saw us watching him, but was not too concerned. Watched us for a while, then continued
                            grazing on the bush.</p>

                        <p>Isn't he just beautiful? And what a lovely pair of horns he has!</p>

                        <p>There is a part of the fence where a heavy vine has caused the fence to sag badly, and when
                            he had had enough, he made his way back and jumped over the sagging fence.</p>

                        <p>I have heard that residents have found many traps in the dense bush behind us. I really hope
                            that this buck does not get caught. He is much nicer in his natural environment.</p>

                    </div>

                </article>
                <hr/>


                <article>

                    <div className={styles.blogImgRight}>
                        <img src={blog_20230311}
                             className={styles.blogImgSm}
                             alt="Johannesburg at evening"
                             title='Johannesburg at evening'/>

                        <div className={styles.blogImgDropdown}>
                            <img src={blog_20230311}
                                 alt="Johannesburg at evening"
                                 title='Johannesburg at evening'/>
                            <p className={styles.blogCaption}>View from our hotel window, shortly before 7pm.</p>
                        </div>
                    </div>

                    <div>
                        <h2>A Trip To The Big City</h2>

                        <pre>2023-03-11</pre>

                        <p>We recently had to make a trip to Johannesburg. Not my favourite place, but the weather was
                            kind, and did not storm on us while we were there, although we did see a storm in the
                            distance from our hotel room on Sunday evening. I must admit, the lightning is awesome!</p>

                        <p>We stayed at a hotel at OR Tambo International Airport for the two days that we were there,
                            so that we would only need to be out on the roads for the trip that we had to make across
                            the city. The hotel was not too bad. It has a very nice restaurant, with lovely food. The
                            view from the restaurant is not fantastic, but one cannot have everything.</p>

                        <p>The view from our room was quite nice. The Gautrain rail ran almost right past, so we could
                            see the train going to and fro, collecting and dropping people at the airport, and we could
                            see the network of roads leading to the airport. How anyone finds their way around, I really
                            don't know. No wonder there are so many accidents, with cars needing to cross lanes on
                            corners and sweeps, and general chaos reigning everywhere.</p>

                        <p>Our outing on Sunday was pleasant. We went to a lovely place called <a href={linkHerbLand}
                                                                                                  target={target}>Herb
                            Land</a>, on Summit Road, Bridle Park, Midrand. Their restaurant, The Herb Cafe, has the
                            most delicious food. The restaurant is on an outdoor wooden deck, overlooking the gardens.
                            There are walkways with benches all over the gardens, where one can sit and relax after a
                            meal, and enjoy the quiet. A very pleasant place for a quiet escape from the Johannesburg
                            rush.</p>

                        <p>It was really - REALLY - nice to return to our little village on Monday morning! It's always
                            nice to go on adventures, but home is definitely the best place to be!</p>

                    </div>

                </article>
                <hr/>

                <article>

                    <div className={styles.blogImgMultiLeft}>

                        <div className={styles.blogImgMulti}>

                            <div className={styles.blogImgMultiImg}>
                                <img src={blog_20221119_01}
                                     className={styles.blogImgSm}
                                     alt="kingfisher chick 1 emerging"
                                     title='kingfisher chick 1 emerging'/>

                                <div className={styles.blogImgDropdown}>
                                    <img src={blog_20221119_01}
                                         alt="kingfisher chick 1 emerging"
                                         title='kingfisher chick 1 emerging'/>
                                    <p className={styles.blogCaption}>The first kingfisher chick emerging from the
                                        nest</p>
                                </div>
                            </div>

                            <div className={styles.blogImgMultiImg}>
                                <img src={blog_20221119_02}
                                     className={styles.blogImgSm}
                                     alt="kingfisher chick 2 emerging"
                                     title='kingfisher chick 2 emerging'/>

                                <div className={styles.blogImgDropdown}>
                                    <img src={blog_20221119_02}
                                         alt="kingfisher chick 2 emerging"
                                         title='kingfisher chick 2 emerging'/>
                                    <p className={styles.blogCaption}>The second kingfisher chick emerging from the
                                        nest</p>
                                </div>

                            </div>

                            <div className={styles.blogImgMultiImg}>
                                <img src={blog_20221119_03}
                                     className={styles.blogImgSm}
                                     alt="kingfisher chick 3 emerging"
                                     title='kingfisher chick 3 emerging'/>

                                <div className={styles.blogImgDropdown}>
                                    <img src={blog_20221119_03}
                                         alt="kingfisher chick 3 emerging"
                                         title='kingfisher chick 3 emerging'/>
                                    <p className={styles.blogCaption}>The third kingfisher chick emerging from the
                                        nest</p>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div>
                        <h2>The Kingfisher Chicks Are Out And About!</h2>

                        <pre>2022-11-19</pre>

                        <p>My wish has been granted, in most spectacular style!</p>

                        <p>I was watching the parent birds coming and going with food this morning, when suddenly, I saw
                            a tiny face appear at the mouth of the nest. My goodness, a baby bird appearing! Watched him
                            looking about, bobbing his head from time to time, exactly as the adults do, then WOW, he
                            hopped out and made his first flight! Disappeared up into the trees in the neighbours
                            garden.</p>

                        <p>I thought that was the end of my excitement, but it was not! Shortly afterwards, yet another
                            little face appeared. This one was a bit more cautious, but after a while he also came right
                            out, and took his first flight. Not quite as well as his sibling - he crash-landed on the
                            ground, but then managed to fly up onto a dead branch that I had (conveniently) left lying
                            against the fence.</p>

                        <p>And then, what do you think happened? Yet another little face appeared at the mouth of the
                            nest. Looked about, and then made his first flight, in the same direction as his first
                            sibling, up into the trees.</p>

                        <p>What a wonderful experience, not only to see a chick emerging from the nest, but THREE
                            chicks.</p>

                        <p>I really hope that they keep safe.</p>

                    </div>

                </article>
                <hr/>

                <article>

                    <div className={styles.blogImgRight}>
                        <img src={blog_20221103}
                             className={styles.blogImgSm}
                             alt="our beautiful kingfisher"
                             title='our beautiful kingfisher'/>

                        <div className={styles.blogImgDropdown}>
                            <img src={blog_20221103}
                                 alt="our beautiful kingfisher"
                                 title='our beautiful kingfisher'/>
                            <p className={styles.blogCaption}>Is he not a BEAUTIFUL bird?</p>
                        </div>
                    </div>

                    <div>
                        <h2>Update on the Kingfisher Family</h2>

                        <pre>2022-11-03</pre>

                        <p>I am not certain, but I think that the kingfisher's eggs have hatched. The male still goes to
                            and fro all day with offerings which he delivers to the nest, but the female has not
                            appeared for a while. I get the feeling that she is mothering a tiny hatchling, maybe more
                            than one, way down in the depths of that tunnel.</p>

                        <p>A few moments before I managed to get this picture, he had been on the branch with a long
                            worm in his beak, might possibly have been an earthworm. Of course, he bashed it mercilessly
                            on the branch, then he flew down and deposited it in the nest, then came out again.</p>

                        <p>I can't wait to see if I can glimpse any young ones in the coming days or weeks!</p>

                    </div>

                </article>
                <hr/>

                <article>

                    <div className={styles.blogImgLeft}>
                        <img src={blog_20221030}
                             className={styles.blogImgSm}
                             alt="first cute baby monkey of the season"
                             title='first cute baby monkey of the season'/>

                        <div className={styles.blogImgDropdown}>
                            <img src={blog_20221030}
                                 alt="first cute baby monkey of the season"
                                 title='first cute baby monkey of the season'/>
                            <p className={styles.blogCaption}>He must be a few weeks old, but this is the first photo I
                                have managed to get of him.</p>
                        </div>
                    </div>

                    <div>
                        <h2>Spring - It Is Baby Season!</h2>

                        <pre>2022-10-30</pre>

                        <p>A few weeks ago, I saw the first of the tiny baby monkeys, clinging to its mother as she
                            crossed the telephone wire up the hill behind our house.</p>

                        <p>I have finally managed to get a photo of him, or her. In fact, there was another parent with
                            a baby a short distance away from here, but I did not manage to get a decent photo of them.
                            They will be tumbling down the hill and leaping from the trees in no time!</p>

                        <p>Our resident pair of breeding brown-hooded kingfishers are nesting in the earth bank, too. I
                            see them going in and out of the nest every day. I do not think that the eggs have hatched
                            yet, but the male is very conscientiously feeding the female. She pops out for some exercise
                            from time to time, but never stays out long. Before you know it, she is back, and pops back
                            into the hole in the ground.</p>

                    </div>

                </article>
                <hr/>

                <article>

                    <div className={styles.blogImgRight}>
                        <img src={blog_20220506}
                             className={styles.blogImgSm}
                             alt="before sunrise"
                             title='before sunrise'/>

                        <div className={styles.blogImgDropdown}>
                            <img src={blog_20220506}
                                 alt="before sunrise"
                                 title='before sunrise'/>
                            <p className={styles.blogCaption}>Beautiful!</p>
                        </div>
                    </div>

                    <div>
                        <h2>Before Sunrise</h2>

                        <pre>2022-05-06</pre>

                        <p>Getting up early is so worthwhile, if you step outside and are presented with a sight like
                            this!</p>
                    </div>

                </article>

                <hr/>

                <article>

                    <div className={styles.blogImgLeft}>
                        <img src={blog_20220330}
                             className={styles.blogImgSm}
                             alt="late night visitor"
                             title='late night visitor'/>

                        <div className={styles.blogImgDropdown}>
                            <img src={blog_20220330}
                                 alt="late night visitor"
                                 title='late night visitor'/>
                            <p className={styles.blogCaption}>Cute, but not in the bedroom!</p>
                        </div>
                    </div>

                    <div>
                        <h2>A Late Night Visitor</h2>

                        <pre>2022-03-30</pre>

                        <p>Went to bed last night, but a few minutes after turning off the light ...</p>
                        <p className='italic'>zzzzzzz...</p>
                        <p>Mosquito! Darn! Got up, sprayed my neck and arms with mosquito repellent. Back to bed, light
                            off ...</p>
                        <p>Something is not right - I sense a presence!</p>
                        <p>Got up but left the light off, and next thing a shadow flitted across the curtain. Now was
                            that inside, or outside?</p>
                        <p>OH GOSH! Whatever it is, it is INSIDE! But that is the biggest moth I have ever seen!</p>
                        <p>But no, it was not a moth. It was a bat.</p>
                        <p>Now I am very fond of bats, but not really so keen on having them in the house. He was flying
                            all over the house, up and down the passage, going into each room that he passed. I tried to
                            coax him out of the back door, but of course you can't coax a creature to do what you want
                            it to do, so he just kept flying around. Went right up to the door a number of times, but
                            just would not go out!</p>
                        <p>Anyway, I was really tired, after a hard day in the garden, and I really wanted to sleep. I
                            lost track of him, unfortunately, but made sure that he was NOT in my bedroom, and NOT in
                            the interleading passage area, then closed the door of the passage and went back to bed. Lay
                            down and closed my eyes ...</p>
                        <p className='italic'>zzzzzzz...</p>
                        <p>OH NO! Now where is that bat?</p>
                        <p>"Here, bat, nice bat, here is a nice fat mosquito for you."</p>
                    </div>

                </article>

                <hr/>

                <article>

                    <div className={styles.blogImgRight}>
                        <img src={blog_20220325}
                             className={styles.blogImgSm}
                             alt="rainy day"
                             title='rainy day'/>
                        <div className={styles.blogImgDropdown}>
                            <img src={blog_20220325}
                                 alt="rainy day"
                                 title='rainy day'/>
                            <p className={styles.blogCaption}>Rainy days here are so restful.</p>
                        </div>
                    </div>

                    <div>
                        <h2>Rain</h2>

                        <pre>2022-03-25</pre>

                        <p>It rained last night, and has been raining all day so far. The world is drenched. Rainy days
                            down here are so lovely.</p>
                        <p>The flowers are blooming, the grass is growing. Unfortunately, the weeds are growing,
                            too.</p>
                        <p>And as I write this, the sun is suddenly struggling to break through the clouds.</p>
                        <p>I don't think he is going to make it ...</p>
                    </div>

                </article>

                <hr/>

                <article>

                    <div className={styles.blogImgLeft}>
                        <img src={elephant}
                             className={styles.blogImgSm}
                             alt="an elephant"
                             title='an elephant'/>
                        <div className={styles.blogImgDropdown}>
                            <img src={elephant}
                                 alt="an elephant"
                                 title='an elephant'/>
                            <p className={styles.blogCaption}>An elephant - the easiest thing in the world to draw.</p>
                        </div>
                    </div>

                    <div>
                        <h2>Learning to Draw</h2>

                        <pre>2022-03-20</pre>

                        <p>I recently dug out my old Wacom Intuos tablet. Have not used it for years, which is sad -
                            having such a nice piece of equipment, and not using it!</p>
                        <p>When learning to draw on a computer, one sits down and thinks, "So what shall I draw?" It is
                            hard to decide! BUT - everyone should start with...</p>
                        <p>AN ELEPHANT!</p>
                        <p>Firstly, because elephants are simply the coolest creatures around.</p>
                        <p>And secondly, because they are just so easy to draw. :-)</p>
                    </div>
                </article>
                <hr/>

                <article>

                    <div className={styles.blogImgRight}>
                        <img src={img01}
                             className={styles.blogImgSm}
                             alt="Dad's first car"
                             title="Dad's first car"/>
                        <div className={styles.blogImgDropdown}>
                            <img src={img01}
                                 alt="Dad's first car"
                                 title="Dad's first car"/>
                            <p className={styles.blogCaption}>And off we go, into the wild outdoors...</p>
                        </div>
                    </div>

                    <div>
                        <h2>A Bit of History</h2>

                        <pre>2022-03-17</pre>

                        <p>A long, long time ago, I was a very small person. My dad loved camping at the coast, and used
                            to pack us in the car and cart us off to the most out-of-the-way places that he could
                            find.</p>
                        <p>That is me, at the front window, with my hair blown up straight by the wind!</p>
                        <p>In the early days, we would camp in a tent, or sometimes just a sail attached to the side of
                            the car. In later years, Dad bought a caravan and we could camp in luxury!</p>
                        <p>Camping is such an adventure for a small child. We have seen some amazing things - watched a
                            snake swallowing a frog once.</p>
                        <p>We have been to a place where we were surrounded by bats. (Very cute and useful little
                            creatures, another creature which is unjustly maligned by most). We have camped in a place
                            where the mosquitoes were like clouds around us. We have camped in torrential rain, and
                            almost could not get back to the main road because the car kept slipping in the mud.</p>
                        <p>And further adventures, too numerous to tell here.</p>
                    </div>
                </article>
                <hr/>
            </div>
        </>
    );
};

export default Blogs;